<template>
	<vue-tel-input
		v-model="inputValue"
		mode="national"
		:dropdown-options="{
			showDialCodeInList: true,
			showDialCodeInSelection: true,
			showFlags: true,
			showSearchBox: true
		}"
		:input-options="{ id: id }"
		:preferred-countries="['AU']"
		:valid-characters-only="true"
		style-classes="phone-input"
		@on-input="valueChanged"
	></vue-tel-input>
</template>

<script>
	import { VueTelInput } from 'vue-tel-input';
	
	export default {
		props: ['id', 'modelValue'],
		emits: ['update:modelValue', 'input'],
		data() {
			return {
				inputValue: '',
				formattedValue: '',
				propagateInputEvent: false
			}
		},
		computed: {
			phoneNumber: { // Enables the use of the v-model directive with this component.
				get() {
					return this.modelValue
				},
				set(value) {
					this.$emit('update:modelValue', value)
				}
			}
		},
		components: {
			VueTelInput
		},
		created() { // When the component is loaded, set the value of the phone number field to the value set in the v-model directive.
			this.inputValue = this.addLeadingPlus(this.phoneNumber); // The phone number field requires the leading plus to be present in order to set the number value correctly, so add this if it isn't there already.
		},
		beforeMount() { // When the component is mounted, there is an input event generated, which we don't want to propagate to the parent component.
			this.propagateInputEvent = false;
		},
		methods: {
			valueChanged(number, phoneObject) { // When the value in the phone number field changes, update the value used by the v-model directive.
				if(number.trim() == '') { // If the value is empty, return an empty string to indicate to the calling component that the value is empty.
					this.phoneNumber = '';
				} else if(phoneObject.valid) { // If the value is a valid phone number, return the phone number in E164 format, and update the phone number field to display the formatted phone number.
					this.inputValue = phoneObject.formatted;
					this.phoneNumber = this.removeLeadingPlus(phoneObject.number); // The phone number returned by the phoneObject includes the leading plus, but we should remove this for standardisation.
				} else { // If the value is not a valid phone number, return FALSE to indicate to the calling component that the value isn't valid.
					this.phoneNumber = false;
				}
				
				// Propagate the event to the parent component, using the standard event name used for other input components.
				if(this.propagateInputEvent) {
					this.$emit('input');
				}
				this.propagateInputEvent = true; // After the initial input event generated when the component is mounted, future input events should be propagated.
			},
			addLeadingPlus(phoneNumber) { // Adds a leading plus symbol to the given phone number if it isn't there already.
				if(phoneNumber != '' && phoneNumber.charAt(0) != '+') {
					phoneNumber = `+${phoneNumber}`;
				}
				
				return phoneNumber;
			},
			removeLeadingPlus(phoneNumber) { // Removes the leading plus from the given phone number if it is present.
				if(phoneNumber != '' && phoneNumber.charAt(0) == '+') {
					phoneNumber = phoneNumber.substr(1);
				}
				
				return phoneNumber;
			}
		},
		watch: {
			modelValue(value) { // When the model value is changed by the parent component, update the actual value displayed in the input field, then manually trigger an input event to force the value to be validated.
				this.inputValue = this.addLeadingPlus(value); // The phone number field requires the leading plus to be present in order to set the number value correctly, so add this if it isn't there already.
				setTimeout(() => document.getElementById(this.id).dispatchEvent(new Event('input')), 1); // This requires a short delay to ensure that the value has been properly updated before attempting to interact with the field
			}
		}
	}
</script>